<template>
  <div id="app">
    <!-- <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay> -->

    <!-- loading skeleton Start -->
    <!-- <v-col v-show="loading == true">
      <v-sheet
        height="565"
        class="pa-3 mb-n5"
        max-width="6000"
        :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-5'"
      >
        <div>
          <v-card-actions>
            <v-skeleton-loader
              width="400"
              type="heading"
            ></v-skeleton-loader>
            <v-spacer></v-spacer>
            
            <v-skeleton-loader
              type="card"
              width="80"
              height="35"
            ></v-skeleton-loader>
            <v-skeleton-loader
              type="card"
              width="80"
              height="35"
              class="ml-2"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>
        
        <div>
          <v-skeleton-loader
            tile
            height="50"
            type="image"
            class="mt-1"
          ></v-skeleton-loader>
        </div>

        <div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-skeleton-loader
              type="image"
              width="250"
              height="20"
              class="rounded-xl mx-n1 mt-1 mb-1"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>

        <div>
          <v-skeleton-loader
            tile
            height="200"
            type="image"
          ></v-skeleton-loader>
          <v-skeleton-loader
            tile
            height="132"
            type="image"
            class="mb-3"
          ></v-skeleton-loader>
        </div>

        <div>
          <v-card-actions>
            <v-skeleton-loader
              width="800"
              type="heading"
              class="mt-1 mx-n2"
            ></v-skeleton-loader>

            <v-spacer></v-spacer>

            <v-skeleton-loader
              type="image"
              width="170"
              height="20"
              class="rounded-xl mx-n1"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>
      </v-sheet>
    </v-col> -->
    <!-- loading skeleton End -->

    <!-- Alert Tidak Punya Akses Halaman -->
    <v-col v-show="this.valid == false">
      <v-alert text type="error">
        Kamu tidak punya akses Ke halaman "User"
      </v-alert>
    </v-col>

    <!-- Alert Data Tersimpan -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      elevation="4"
      color="success"
      v-model="Alert"
      class="rounded-lg"
      icon="mdi-check-bold"
      transition="slide-x-transition"
    >
      <v-icon class="mr-1">check-circle</v-icon>
      Data Berhasil Disimpan
    </v-alert>

    <!-- Alert Data Berhasil Terubah -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      elevation="4"
      color="success"
      class="rounded-lg"
      icon="mdi-check-bold"
      v-model="AlertBerhasilTerubah"
      transition="slide-x-transition"
    >
      Data Berhasil Diubah
    </v-alert>

    <!-- Alert Data Sudah Terhapus -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      color="error"
      elevation="4"
      class="rounded-lg"
      icon="mdi-delete"
      v-model="AlertDataTerhapus"
      transition="slide-x-transition"
    >
      Data Berhasil Dihapus
    </v-alert>

    <!-- ---------------------------------------------------- -->
    <v-container fluid v-show="this.valid == true">
      <v-card tile outlined class="elevation-3 pa-2">
          <v-toolbar
            flat
            outlined
            height="55"
            color="dark"
            v-resize="onResize"
          >
            <v-toolbar-title>
              Data Pengguna
            </v-toolbar-title>
            <v-divider inset vertical class="ml-2"></v-divider>
            <v-spacer></v-spacer>

            <!-- Menu Export Data -->
            <div class="text-center mx-2">
              <v-menu
                v-model="MenuExport"
                :close-on-content-click="false"
                :nudge-width="1"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <!-- Btn Export Mode Biasa -->
                  <v-btn
                    @click="MenuExport = !MenuExport"
                    class="text-capitalize"
                    v-show="mobile == false"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    text
                    :disabled="loading == true"
                  >
                    <v-icon class="ml-n2 mr-1">mdi-table-arrow-right</v-icon>
                    <span class="subtitle-1">Export Data</span>
                    <v-icon class="ml-1 mr-n3">{{ MenuExport ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </template>

                <v-card outlined height="100" class="pa-2" v-show="MenuExport">
                  <!-- PDF -->
                  <div class="mb-2">
                    <v-btn
                      text
                      block
                      outlined
                      @click.native="PdfExport"
                      class="text-capitalize subtitle-1"
                    >
                      Pdf
                      <v-icon right>mdi-adobe-acrobat</v-icon>
                    </v-btn>
                  </div>

                  <!-- EXCEL -->
                  <div>
                    <v-btn
                      text
                      block
                      outlined                       
                      @click.native="excelExport"
                      class="text-capitalize subtitle-1"
                    >
                      Excel
                      <v-icon right>mdi-microsoft-excel</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </v-menu>

              <!-- Btn Export Dialog Mode Mobile -->
              <v-dialog
                v-model="DialogPilihExport"
                max-width="200"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="mobile == true"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    small
                    text
                    fab
                    :disabled="loading == true"
                  >
                    <v-icon>mdi-table-arrow-right</v-icon>
                  </v-btn>
                </template>
                <v-card class="rounded-lg">
                  <v-toolbar dense>
                    <v-layout justify-center>
                      <v-toolbar-title>
                        Pilih Export File
                      </v-toolbar-title>
                    </v-layout>
                  </v-toolbar>
                  <v-card class="pa-2">
                    <v-btn text block outlined class="text-capitalize subtitle-1 mb-2" @click.native="PdfExport">
                      Pdf
                      <v-icon right>mdi-adobe-acrobat</v-icon>
                    </v-btn>

                    <v-btn text block outlined class="text-capitalize subtitle-1" @click.native="excelExport">
                      Excel
                      <v-icon right>mdi-microsoft-excel</v-icon>
                    </v-btn>
                  </v-card>
                </v-card>
              </v-dialog>
            </div>

            <!-- Dialog Tambah User -->
            <v-dialog
              v-model="DialogTambahUser"
              max-width="400px"
              scrollable
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <!-- Btn Tambah Mode Desktop -->
                <v-btn
                  class="text-capitalize mr-n2"
                  v-show="mobile == false && validcreate == true"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  text
                  :disabled="loading == true"
                >
                  <v-icon class="ml-n2 mr-1">mdi-plus-thick</v-icon>
                  <span class="mr-n1 subtitle-1">Tambah</span>
                </v-btn>

                <!-- Btn Tambah Mode Mobile -->
                <v-btn 
                  class="mr-n2"
                  v-show="mobile == true"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  small
                  text
                  fab
                  :disabled="loading == true"
                >
                  <v-icon>mdi-plus-thick</v-icon>
                </v-btn>
              </template>
              <v-card class="rounded-lg">
                <v-toolbar
                  flat
                >
                  <v-toolbar-title>
                    <span class="title">{{ formTitleUser }}</span>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    fab
                    text
                    small
                    @click="KeluarDialogUser"
                  >
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                
                <v-card-text>
                  <div class="mt-5 mr-n1 ml-n1">
                    <v-row>
                      <v-card 
                        outlined
                        class="pa-3 rounded-lg"
                      >
                        <div class="mt-5">
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                dense
                                clearable
                                label="Kode"
                                maxlength="15"
                                color="dark"
                                disabled
                                v-model="editedItem.Kode"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                dense
                                clearable
                                counter
                                label="Nama"
                                color="dark"
                                maxlength="10"
                                :hint="alertNama"
                                @keyup="cariKode(Nama)"
                                v-model="Nama"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                dense
                                clearable
                                label="Password"
                                :append-icon="TampilkanPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="TampilkanPassword = !TampilkanPassword"
                                :type="TampilkanPassword ? 'text' : 'password'"
                                color="dark"
                                v-model="password"
                                v-show="editedIndex == -1"
                                :disabled="textAktif"
                              ></v-text-field>

                              <v-text-field
                                dense
                                clearable
                                label="Password Lama"
                                color="dark"
                                type="password"
                                disabled
                                v-model="editedItem.password"
                                v-show="editedIndex == 1"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                dense
                                clearable
                                label="Password Baru"
                                color="dark"
                                v-model="passbaru"
                                :append-icon="TampilkanPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="TampilkanPassword2 = !TampilkanPassword2"
                                :type="TampilkanPassword2 ? 'text' : 'password'"
                                v-show="editedIndex == 1"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                dense
                                clearable
                                label="Ulangi Password Baru"
                                color="dark"
                                :append-icon="TampilkanPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="TampilkanPassword1 ? 'text' : 'password'"
                                v-model="verifpassbaru"
                                @click:append="TampilkanPassword1 = !TampilkanPassword1"
                                v-show="editedIndex == 1"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                dense
                                readonly
                                label="Kode Karyawan"
                                color="dark"
                                :disabled="textAktif"
                                v-model="editedItem.KodeKaryawan"
                              >
                                <template v-slot:append-outer>
                                  <v-dialog
                                    v-model="DialogKodeKaryawan"
                                    max-width="1000px"
                                    scrollable
                                    persistent
                                  >
                                    <template v-slot:activator="{on, attrs}">
                                      <v-btn
                                        dark
                                        x-small
                                        height="25"
                                        v-bind="attrs"
                                        v-on="on"
                                        color="red darken-4"
                                        v-show="textAktif == false && editedItem.KodeKaryawan == null || editedIndex == -1"
                                      >
                                        <v-icon>mdi-dots-horizontal</v-icon>
                                      </v-btn>
                                      <!-- kalo ada kode nya jadi hapus dlu  -->
                                      <v-btn
                                        dark
                                        x-small
                                        height="25"
                                        v-bind="attrs"
                                        @click="hapusKodeKaryawan"
                                        color="red darken-4"
                                        v-show="textAktif == false && editedIndex != -1 && editedItem.KodeKaryawan != null"
                                      >
                                        <v-icon>mdi-trash-can</v-icon>
                                      </v-btn>
                                    </template>
                                    <v-card>
                                      <v-toolbar
                                        flat
                                      >
                                        <v-toolbar-title>
                                          <span class="headline">Pilih Kode Karyawan</span>
                                        </v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          fab
                                          text
                                          small
                                          @click="DialogKodeKaryawan = false"
                                        >
                                          <v-icon>mdi-window-close</v-icon>
                                        </v-btn>
                                      </v-toolbar>
                                      <v-divider></v-divider>
                                      <v-card-text>
                                        <div class="mb-n2 mr-n3 ml-n3">                                           
                                          <v-card outlined class="rounded-lg mt-3 elevation-6">
                                            <div class="pa-2">
                                              <ejs-grid
                                                ref='grikodejabatan'
                                                height="208"
                                                gridLines='Both'
                                                id="kodejabatanview"
                                                :dataSource="DataKodeKaryawan"
                                                :allowPaging="true"
                                                :allowSorting='true'
                                                :allowResizing='true'
                                                :toolbar='toolbarOptions'
                                                :recordDoubleClick="rowDblClickKodeKaryawan"
                                                :pageSettings='pageSettings'
                                                :rowSelected="rowSelectedKodeKaryawan" 
                                              >
                                                <e-columns>
                                                  <e-column field='Nama' headerText='Nama' textAlign='Left' width=150></e-column>
                                                  <e-column field='NRK' headerText='Nrk' width=300></e-column>
                                                  <e-column field='TglLahir' headerText='Tanggal Lahir' width=150></e-column>
                                                  <e-column field='AlamatKTP' headerText='Alamat Ktp' width=200></e-column>
                                                  <e-column field='TempatLahir' headerText='Tempat Lahir' width=200></e-column>
                                                </e-columns>
                                              </ejs-grid>
                                            </div>
                                          </v-card>                                      
                                        </div>     
                                      </v-card-text>                                   
                                      <v-divider></v-divider>
                                      <v-card-actions>
                                        <span class="d-inline-block text-truncate">
                                          Status : <span>Pilih Kode</span>
                                        </span>
                                      <v-spacer></v-spacer>
                                        <v-btn
                                          dark
                                          color="red darken-4 mx-n2"
                                          class="text-capitalize"
                                          @click="DialogKodeKaryawan = false"
                                        >
                                          <v-icon class="mr-1">mdi-check-circle</v-icon>
                                          Pilih
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-dialog>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card>
                    </v-row>
                  </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <span class="d-inline-block text-truncate">
                    Status : <span>{{Status}}</span>
                  </span>
                <v-spacer></v-spacer>
                <!-- <v-btn
                    outlined
                    color="red darken-4"
                    class="text-capitalize mx-4"
                    @click="Reset"
                  >
                    <v-icon class="mr-1">mdi-close-circle</v-icon>
                    Batal
                  </v-btn> -->
                  
                  <v-btn
                    dark
                    color="red darken-4 mx-n2"
                    class="text-capitalize"
                    @click="Simpan"
                  >
                    <v-icon class="mr-1">mdi-content-save</v-icon>
                    Simpan
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <div v-show="loading == true">
              <v-progress-linear
                :active="loading"
                :indeterminate="loading"
                absolute
                bottom
                height="6"
                color="red darken-4"
              ></v-progress-linear>
            </div>
          </v-toolbar>

          <div>
            <ejs-grid
              ref='griduser'
              style="height: 100%;"
              height="43vh"
              gridLines='Both'
              id="userview"
              :dataSource="DataUser"
              :allowPaging="true"
              :allowSorting='true'
              :allowResizing='true'
              :allowGrouping='true'
              :toolbar='toolbarOptions'
              :commandClick="commandClick"
              :pageSettings='pageSettings'
              :allowExcelExport='true'
              :allowPdfExport='true'
            >
              <e-columns>
                <e-column headerText="Aksi" textAlign='Center' width="120" :commands="commands" :lockColumn='true' :customAttributes="customAttributes">
                  <div class="btn-group">
                    <v-btn type="button" class="btn btn-default" (click)="prediemRowEdit($event)">
                      <i class="fa fa-pencil"></i>
                    </v-btn>
                    <v-btn type="button" class="btn btn-default" (click)="prediemRowDelete($event)">
                      <i class="fa fa-trash"></i>
                    </v-btn>
                  </div>
                </e-column>
                <e-column field='Kode' headerText='Kode' textAlign='Left' width=200></e-column>
                <e-column field='Nama' headerText='Nama' width=200></e-column>
                <e-column field='karyawan.Nama' headerText='Pengguna Akun' width=200></e-column>
                <e-column field='password' headerText='Password' width=150 :visible='false'></e-column>
                <e-column field='DiBuatTgl' headerText='DiBuatTgl' textAlign='Left' width=150></e-column>
                <e-column field='DiUbahTgl' headerText='DiUbahTgl' textAlign='Left' width=150></e-column>
              </e-columns>
            </ejs-grid>
          </div>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import Vue from "vue";
import { GridPlugin, Page, Sort, Group, Resize, Toolbar, Search,CommandColumn,ExcelExport,PdfExport } from "@syncfusion/ej2-vue-grids";
import api from "@/services/http";

Vue.use(GridPlugin);

export default {
  data() {
    return {
      mobile:null,
      valid:null,
      validcreate:false,
      alertNama:'',
      UserMenuItems:[],
      passbaru:null,
      textAktif:true,
      DialogKodeKaryawan:false,
      umenu:[],
      DataKodeKaryawan:[],
      verifpassbaru:null,
      loading:true,
      TampilkanPassword:false,
      TampilkanPassword2:false,
      TampilkanPassword1:false,
      Nama:null,
      DialogPilihExport:false,
      windowSize: {x: 0, y: 0},
      MenuExport: false,
      commands: [],
      customAttributes : {class: 'customcss'},
      Alert:false,
      akun:[],
      usermenu:null,
      AlertDataTerhapus:false,
      AlertBerhasilTerubah:false,
      user:[],
      token:null,
      password:'',
      DialogTambahUser: false,
      form: new FormData,
      editedIndex: -1,

      defaultItem: {
        id:"",
        Kode: "",
        Nama: "",
        password: "",
        UserMenu: "",
        KodeKaryawan:"",
        StatusData:"",
      },
      editedItem: {
        id:"",
        Kode: "",
        Nama: "",
        password: "",
        UserMenu: "",
        KodeKaryawan:"",
        StatusData:"",
      },
      toolbarOptions: ['Search'],
      DataUser: [],
      pageSettings: {pageSize: 10, pageSizes :['5','10','15','20','50','All']},
    };
  },
  

  provide: {
    grid: [Page, Sort, Group, Resize, Toolbar, CommandColumn, Search,ExcelExport,PdfExport ]
  },

  mounted(){
    this.token = localStorage.getItem('token')
    this.user = JSON.parse(localStorage.getItem('user'))
    // await Promise.all([this.saya()])
    // await this.saya()
    if (this.Permission.some(a => a == 'R')) {
      this.valid = true
      this.getdata()
      this.editedItem.Tgl_Masuk = this.date
      this.editedItem.Tgl_Lahir = this.date
      this.editedItem.Tgl_Phk = this.date
      this.getKode()
    }else{
      this.loading = false
      this.valid = false
    }
    if (this.Permission.some(a => a == 'C')) {
     this.validcreate = true
    }
    if (this.Permission.some(a => a == 'U')) {
     this.commands.push({buttonOption: { cssClass: "e-flat Edit", iconCss: "e-edit e-icons", content: " "} })
    }
    if (this.Permission.some(a => a == 'D')) {
     this.commands.push({ buttonOption: { cssClass:"e-flat Delete", iconCss: "e-delete e-icons", content: " "} })
    }
    // await this.getdata()
    // this.getKode()
    // console.log('oo',this.Permission)
  },

   created(){
    //get action for this page
    // this.Permission = Object.assign([],this.$route.params.Permission.replaceAll('"','').replaceAll('[','').replaceAll(']','').replaceAll(',',''))
    // var array = (new Function("return [" + this.$route.params.Permission+ "];")());
    // console.log('param',this.$route.params.Permission)
    let permisi = this.$route.params.Permission
    this.Permission = permisi == null ? [] : permisi.split(',')
    // console.log('param',this.$route.params.Permission)
    // console.log('ok',this.Permission)
    },

  computed: {
    formTitleUser() {
      return this.editedIndex === -1 ? 'Tambah User Baru' : 'Ubah Data User'
    },
    Status () {
      return this.editedIndex === -1 ? 'Baru' : 'Ubah'
    },
  },
  
  watch: {
  
      // let a = this.DataUser.filter( function(item){return (item.UserMenu == newval.toUpperCase());} )
      // this.umenu = a
    windowSize(){
      if (this.windowSize.x < 450) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },

    DialogTambahUser (val) {
      val || this.KeluarDialogUser()
    },
    Nama(){
      //  if (Nama != null && this.alertNama == ) {
      //     this.textAktif = false
      //   }else{
      //     this.textAktif = true
      //   }
    },

    Alert(){
      this.AlertItem()
    },
    AlertDataTerhapus(){
      this.AlertItem()
    },
    AlertBerhasilTerubah(){
      this.AlertItem()
    },
  },

  methods: {
    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    cariKode(Nama){
        if (Nama == null || Nama == '' || Nama.length == 0) {
          this.textAktif = true
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
          })
        }else{
      let ax = '0101/'
      let findnama = ax+Nama.toUpperCase()
      let a = this.DataUser.filter( function(item){return (item.Kode == findnama);} );
        if (a.length > 0) {
        this.alertNama = "Nama User ini sudah di gunakan silahkan coba yg lain"
        this.textAktif = true
        this.editedItem.Kode = ''
        // this.editedItem.Kode = ax+ss+1
        }else{
          this.textAktif = false
          this.alertNama = ''
          this.editedItem.Kode = findnama
          this.editedItem.Nama = Nama
          
        }
      }
    },
  // async saya(){
  //      if (this.$menu == undefined) {
  //        await api.get('/menu?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
  //       res=>{
  //         if (res) {
  //           this.akun = res.data
  //           let route = this.$route.name
  //           let a = this.akun.filter( function(item){return (item.Route == route);} );
  //           if (a.length > 0) {
  //              this.valid = true
  //           }else{
  //             this.valid = false
  //             this.loading = false
  //           }
  //           //do noting
  //         }
  //       }).catch(err=>{
  //         // alert(err)
  //         if (err == "Error: Request failed with status code 401" && this.$route.path != "/login") {
  //           this.logout()
  //         }
  //       })
  //      }else{
  //       this.akun = this.$menu
  //       let route = this.$route.name
  //           let a = this.akun.filter( function(item){return (item.Route == route);} );
  //           if (a.length > 0) {
  //              this.valid = true
  //           }else{
  //             this.valid = false
  //             this.loading = false
  //           }
  //      }     
  //   },
      // cekmenu(){
        
      // },
    Toast(data){
      this.$swal.fire({
      toast: true,
      icon: 'success',
      title: data,
      animation: false,
      position: 'top-right',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true})
    },

    Simpan(){
      if(this.formTitleUser === "Tambah User Baru"){
        // let Usermenu =  this.editedItem.UserMenu.UserMenu
        // let menu = [{"Nama":"Beranda","Object":"Link","Visible":1,"Route":"/","Icon":"mdi-home"},{"Nama":"Master","Object":"List","Visible":0,"Icon":"mdi-database"},{"Nama":"Setting","Object":"List","Visible":1,"Icon":"mdi-cog"}]
        // if (Usermenu == null || Usermenu == '') {Usermenu = this.editedItem.UserMenu}
          api.post('/register?token='+this.token,{
            Kode: this.editedItem.Kode,
            Nama: this.editedItem.Nama,
            // UserMenu: Usermenu,
            password: this.password,
            KodeKaryawan: this.editedItem.KodeKaryawan,
            // Menu:menu
            })
            .then((res)=>{
              if (res) {
                this.getdata()
                this.getKode()
                this.Toast('Data Di Save')
              } else {
                //
              }
            })
      }else if(this.formTitleUser === "Ubah Data User"){
         api.put("/user/"+this.editedItem.id+"?token="+this.token,{
            Kode: this.editedItem.Kode,
            Nama: this.editedItem.Nama,
            password: this.passbaru,
            StatusData: this.editedItem.StatusData,
            KodeKaryawan: this.editedItem.KodeKaryawan,
            UserMenu: this.editedItem.UserMenu
					},
          { headers: {"Authorization" : `Bearer ${this.token}` } })
					.then((res) => {
						if (!res) {
							//do nothing
						}else{
              this.getdata()
              this.getKode()
              this.Toast('Data Di Save')
						}
						
					})
      }
      this.KeluarDialogUser()    
    },

    commandClick: function(args) {
      if (args.target.classList.contains("custombutton")){
        // let data = JSON.stringify(args.rowData);
		
        // console.log(args.rowData);
      } else if (args.target.classList.contains("Delete")){
        var r = confirm("Yakin Hapus Data?");
        if (r == true) { 
          api
            .delete("/user/"+args.rowData.id+'?token='+this.token)
            .then((res) =>{
              // console.log(res);
              if (res) {
              this.getdata()
              this.getKode()
              this.Toast('Data Di Hapus')
              this.AlertDataTerhapus = true
              }
              
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (args.target.classList.contains("Edit")){
        this.textAktif = false
        let data = args
            this.editedIndex = 1;
            // console.log(data)
            this.editedItem = data.rowData
            this.DialogTambahUser = true

          if (args.rowData.Aktif == 0) {
            this.dataTemp = args.rowData
            this.dialogAktifData = true
            }else{
                let data = args;
                this.editedIndex = 1;
                this.Kode = data.rowData.Kode
                this.editedItem.Nama = data.rowData.Nama
                this.Nama = data.rowData.Nama
                this.editedItem.Password = data.rowData.Password
                this.editedItem.StatusData = data.rowData.StatusData
                this.editedItem.UserMenu = data.rowData.UserMenu
                // this.dialogWO = true;
            }
      }
    },

    PdfExport(){
      let data = document.getElementById('userview').ej2_instances[0];
      // console.log(data)
      data.pdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName:"ReportUser.pdf"
      }
      data.columns[1].visible = false
      data.columns[4].visible = false
      data.columns[3].visible = false
      // let pdfExportProperties = {
       
      // };
      // this.$refs.griduser.columns[0].visible = false;
      // this.$refs.griduser.columns[1].visible = true;
      this.$refs.griduser.pdfExport(data.pdfExportProperties);
    },
    excelExport(){
      this.$refs.griduser.excelExport();
      // console.log(this.$refs.griduser)
      // let data = document.getElementById('userview').ej2_instances[0];
      // console.log(data)
      // data.excelExport();
    },

    KeluarDialogUser () {
      this.DialogTambahUser = false
      this.$nextTick(() => {
        this.Nama = null
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.Kode = null
        this.textAktif = true
      })
    },

    // beforeExport: function(args) {
      
    // },
    rowSelectedKodeKaryawan:function(args){
      this.editedItem.KodeKaryawan = args.data.KodeKaryawan
    },
    rowDblClickKodeKaryawan: function(args){
      this.editedItem.KodeKaryawan = args.rowData.KodeKaryawan
      this.DialogKodeKaryawan = false
    },

    getdata(){
      // if (this.valid == true) {
       api.get('/user?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
				res => {
          this.loading = false
          this.DataUser = res.data
				},
				err => {
					console.log(err);
				}
			)
      // }
      
    },
    hapusKodeKaryawan(){
      this.editedItem.KodeKaryawan = null
    },
   getKode(){
      if (this.valid == true) {
        let datauser = this.DataUser
       api.get('/karyawan?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
				res => {
          let d= []
          datauser.forEach(element => {
            d.push(element.KodeKaryawan)
          });
         let datauserset = [...new Set(d)]
         var filtered = datauserset.filter(function (el) {
            return el != null;
          });
          let fild = []
          for (let i = 0; i < res.data.length; i++) {
            for (let index = 0; index < filtered.length; index++) {
             const element = res.data.findIndex(x => x.KodeKaryawan === filtered[index])
             fild.push(element)
            }
          }
          let datakey = [...new Set(fild)]
          const indexSet = new Set(datakey);
          const datakaryawanfiltered = res.data.filter((value, i) => !indexSet.has(i));
          this.DataKodeKaryawan = datakaryawanfiltered
				},
				err => {
					console.log(err);
				}
			)
      }
    },

    Reset() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    logout(){
        api.post('/logout', this.token)
        .then(res=>{
          // console.log(res)
          if (res) {
            //do nothing
          }
        })
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        location.reload(false)
      },

    AlertItem(){
      window.setInterval(() => {
        this.Alert = false;
        this.AlertDataTerhapus = false;
        this.AlertBerhasilTerubah = false;
      }, 3000)
    },
  },
}
</script>

<style scoped>
.custom-file-input {
  border: 1px solid #ccc;
  display: inline-block solid #ccc;
  padding: 6px 6px 6px;
  cursor: pointer;
}

.e-grid .e-rowcell.customcss{
  background-color: #d6d6d6;
}
.e-grid .e-headercell.customcss{
  background-color: #ecedee;
}
</style>


{% endraw %}